import * as React from "react";
import Svg from "../Svg";
import {SvgProps} from "../types";

const Icon: React.FC<React.PropsWithChildren<SvgProps>> = (props) => {
  return (
    <Svg viewBox="0 0 18 18" {...props}>
      <path
        d="M6.75 14.5125C5.45625 14.2875 4.33125 13.725 3.375 12.8812C3.43125 11.8125 4.66875 11.1375 6.1875 10.8V9.675C4.5 9.95625 3.0375 10.6875 2.475 11.8688C1.74375 10.8563 1.2375 9.675 1.18125 8.38125H7.3125C7.65 7.93125 8.04375 7.5375 8.4375 7.25625V6.13125C10.5188 6.01875 12.6 5.2875 13.275 3.825C13.8938 4.66875 14.3437 5.68125 14.5125 6.75H15.6375C15.1312 2.925 11.8687 0 7.875 0C3.54375 0 0 3.54375 0 7.875C0 11.8687 2.925 15.1312 6.75 15.6937V14.5125ZM1.18125 7.3125C1.29375 6.01875 1.74375 4.8375 2.53125 3.825C2.8125 4.44375 3.375 5.00625 4.21875 5.4C5.0625 5.85 6.1875 6.075 7.36875 6.13125V7.25625H1.18125V7.3125ZM7.3125 5.0625C5.2875 4.95 3.43125 4.1625 3.375 2.86875C4.44375 1.9125 5.79375 1.29375 7.3125 1.18125V5.0625ZM8.4375 5.0625V1.18125C9.95625 1.29375 11.3063 1.9125 12.375 2.86875C12.3187 4.1625 10.4625 4.95 8.4375 5.0625ZM15.3562 14.5687C15.975 13.7812 16.3125 12.825 16.3125 11.8125C16.3125 9.3375 14.2875 7.3125 11.8125 7.3125C9.3375 7.3125 7.3125 9.3375 7.3125 11.8125C7.3125 14.2875 9.3375 16.3125 11.8125 16.3125C12.825 16.3125 13.7812 15.975 14.5687 15.3562L17.0437 17.8313C17.1562 17.9438 17.325 18 17.4375 18C17.6063 18 17.7188 17.9438 17.8313 17.8313C18.0563 17.6063 18.0563 17.2687 17.8313 17.0437L15.3562 14.5687ZM11.8125 15.1875C9.95625 15.1875 8.4375 13.6687 8.4375 11.8125C8.4375 9.95625 9.95625 8.4375 11.8125 8.4375C13.6687 8.4375 15.1875 9.95625 15.1875 11.8125C15.1875 13.6687 13.6687 15.1875 11.8125 15.1875ZM9.5625 12.375C9.225 12.375 9 12.15 9 11.8125C9 10.2375 10.2375 9 11.8125 9C12.15 9 12.375 9.225 12.375 9.5625C12.375 9.9 12.15 10.125 11.8125 10.125C10.8562 10.125 10.125 10.8562 10.125 11.8125C10.125 12.15 9.9 12.375 9.5625 12.375Z"
      />
    </Svg>
  );
};

export default Icon;
