import {
  __async,
  __spreadValues
} from "../chunk-2L3ZO4UM.mjs";

// connectors/bitgetWallet/index.ts
import { InjectedConnector } from "wagmi/connectors/injected";
var mainnetIds = [1, 10, 137, 42161];
var _a;
var BitgetWalletConnector = class extends InjectedConnector {
  constructor({ chains: _chains, options: _options } = {}) {
    const options = __spreadValues({
      name: "BitgetWallet",
      icon: "/images/wallets/bitget.png"
    }, _options);
    const chains = _chains == null ? void 0 : _chains.filter((v) => mainnetIds.includes(v.id));
    super({
      chains,
      options
    });
    this.id = "BitgetWallet";
    this.ready = typeof window !== "undefined" && !!this.findProvider((_a = window == null ? void 0 : window.bitkeep) == null ? void 0 : _a.ethereum);
  }
  getProvider() {
    return __async(this, null, function* () {
      var _a2;
      if (typeof window !== "undefined") {
        this.provider = (_a2 = window.bitkeep) == null ? void 0 : _a2.ethereum;
      }
      return this.provider;
    });
  }
  getReady(ethereum) {
    if (!ethereum)
      return;
    if (!ethereum.isBitKeep)
      return;
    if (ethereum.isBraveWallet && !ethereum._events && !ethereum._state)
      return;
    if (ethereum.isTokenPocket)
      return;
    if (ethereum.isTokenary)
      return;
    return ethereum;
  }
  findProvider(ethereum) {
    if (ethereum == null ? void 0 : ethereum.providers)
      return ethereum.providers.find(this.getReady);
    return this.getReady(ethereum);
  }
};
export {
  BitgetWalletConnector
};
