import { useMemo } from 'react'
// import { LinkStatus } from '@pancakeswap/uikit/src/widgets/Menu/types'
import { useTheme } from '@pancakeswap/hooks'
import { useTranslation } from '@pancakeswap/localization'
import { useActiveChainId } from 'hooks/useActiveChainId'
import config, { ConfigMenuItemsType } from '../config/config'

export const useMenuItems = (): ConfigMenuItemsType[] => {
  const {
    t,
    currentLanguage: { code: languageCode },
  } = useTranslation()
  const { chainId } = useActiveChainId()
  const { isDark } = useTheme()

  const menuItems = useMemo(() => {
    return config(t, isDark, languageCode, chainId)
  }, [t, isDark, languageCode, chainId])

  return useMemo(() => {
    // if (menuItemsStatus && Object.keys(menuItemsStatus).length) {
    //   return menuItems.map((item) => {
    //     const innerItems = item.items.map((innerItem) => {
    //       return innerItem
    //     })
    //     return { ...item, items: innerItems }
    //   })
    // }
    return menuItems
  }, [t, menuItems])
}
