import React from "react";
import {useTheme} from "styled-components";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<React.PropsWithChildren<SvgProps>> = (props) => {
    const { isDark } = useTheme()
    return (
    <Svg viewBox="0 0 1176 1176" {...props} >
        {
            isDark || props?.isDark ? (
                <g>
                    <g>
                        <ellipse cx="588" cy="588" rx="588" ry="588" fill="#FDBC15" fillOpacity="1"/>
                    </g>
                    <g>
                        <g>
                            <g transform="matrix(0.7071067690849304,-0.7071067690849304,0.7071067690849304,0.7071067690849304,-376.85490348387066,266.27922878217396)">
                                <rect x="133" y="588.0437316894531" width="372" height="100" rx="0" fill="#000000"
                                      fillOpacity="1"/>
                            </g>
                            <g transform="matrix(-0.7071067690849304,-0.7071067690849304,0.7071067690849304,-0.7071067690849304,538.0440864456996,1540.3739924153833)">
                                <rect x="588.0439453125" y="658.7544250488281" width="372" height="100" rx="0" fill="#000000"
                                      fillOpacity="1"/>
                            </g>
                        </g>
                        <g>
                            <g transform="matrix(0.7071067690849304,-0.7071067690849304,0.7071067690849304,0.7071067690849304,-264.09100958156887,538.5153348798722)">
                                <rect x="518" y="588.0437316894531" width="372" height="100" rx="0" fill="#000000"
                                      fillOpacity="1"/>
                            </g>
                            <g transform="matrix(-0.7071067690849304,-0.7071067690849304,0.7071067690849304,-0.7071067690849304,1195.2801925433978,1812.6100985130815)">
                                <rect x="973.0439453125" y="658.7544250488281" width="372" height="100" rx="0" fill="#000000"
                                      fillOpacity="1"/>
                            </g>
                        </g>
                        <g transform="matrix(-0.7071067690849304,-0.7071067690849304,0.7071067690849304,-0.7071067690849304,76.97352683544159,1735.883461356163)">
                            <rect x="398" y="852" width="100" height="100" rx="0" fill="#000000" fillOpacity="1"/>
                        </g>
                        <g transform="matrix(-0.7071067690849304,-0.7071067690849304,0.7071067690849304,-0.7071067690849304,730.7954193949699,2006.7053539156914)">
                            <rect x="781" y="852" width="100" height="100" rx="0" fill="#000000" fillOpacity="1"/>
                        </g>
                    </g>
                </g>
            ) : (
                <g>
                    <g>
                        <ellipse cx="588" cy="588" rx="588" ry="588" fill="#333333" fillOpacity="1"/>
                    </g>
                    <g>
                        <g>
                            <g transform="matrix(0.7071067690849304,-0.7071067690849304,0.7071067690849304,0.7071067690849304,-376.85490348387066,266.27922878217396)">
                                <rect x="133" y="588.0437316894531" width="372" height="100" rx="0" fill="#EFB80A"
                                      fillOpacity="1"/>
                            </g>
                            <g transform="matrix(-0.7071067690849304,-0.7071067690849304,0.7071067690849304,-0.7071067690849304,538.0440864456996,1540.3739924153833)">
                                <rect x="588.0439453125" y="658.7544250488281" width="372" height="100" rx="0" fill="#EFB80A"
                                      fillOpacity="1"/>
                            </g>
                        </g>
                        <g>
                            <g transform="matrix(0.7071067690849304,-0.7071067690849304,0.7071067690849304,0.7071067690849304,-264.09100958156887,538.5153348798722)">
                                <rect x="518" y="588.0437316894531" width="372" height="100" rx="0" fill="#EFB80A"
                                      fillOpacity="1"/>
                            </g>
                            <g transform="matrix(-0.7071067690849304,-0.7071067690849304,0.7071067690849304,-0.7071067690849304,1195.2801925433978,1812.6100985130815)">
                                <rect x="973.0439453125" y="658.7544250488281" width="372" height="100" rx="0" fill="#EFB80A"
                                      fillOpacity="1"/>
                            </g>
                        </g>
                        <g transform="matrix(-0.7071067690849304,-0.7071067690849304,0.7071067690849304,-0.7071067690849304,76.97352683544159,1735.883461356163)">
                            <rect x="398" y="852" width="100" height="100" rx="0" fill="#EFB80A" fillOpacity="1"/>
                        </g>
                        <g transform="matrix(-0.7071067690849304,-0.7071067690849304,0.7071067690849304,-0.7071067690849304,730.7954193949699,2006.7053539156914)">
                            <rect x="781" y="852" width="100" height="100" rx="0" fill="#EFB80A" fillOpacity="1"/>
                        </g>
                    </g>
                </g>
            )
        }
    </Svg>
  );
};

export default Icon;
