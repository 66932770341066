export const PANCAKE_EXTENDED = ''
// export const COINGECKO = 'https://tokens.pancakeswap.finance/coingecko.json'
export const COINGECKO = 'local://api/token/coingecko'
export const PANCAKE_ETH_DEFAULT = 'https://tokens.pancakeswap.finance/pancakeswap-eth-default.json'
export const PANCAKE_ETH_MM = ''
export const PANCAKE_BSC_MM = ''
export const COINGECKO_ETH = 'https://tokens.coingecko.com/uniswap/all.json'
export const CMC = 'local://api/token/cmc'

// export const ETH_URLS = [PANCAKE_ETH_DEFAULT, PANCAKE_ETH_MM, COINGECKO_ETH]
// export const BSC_URLS = [PANCAKE_EXTENDED, CMC, COINGECKO, PANCAKE_BSC_MM]

export const ETH_URLS = [COINGECKO_ETH]
export const BSC_URLS = [CMC, COINGECKO]

// List of official tokens list
// export const OFFICIAL_LISTS = [PANCAKE_EXTENDED, PANCAKE_ETH_DEFAULT, PANCAKE_ETH_MM]
export const OFFICIAL_LISTS = []

export const UNSUPPORTED_LIST_URLS: string[] = []
export const WARNING_LIST_URLS: string[] = []

// lower index == higher priority for token import
export const DEFAULT_LIST_OF_LISTS: string[] = [
    ...BSC_URLS,
    ...ETH_URLS,
    ...UNSUPPORTED_LIST_URLS, // need to load unsupported tokens as well
    ...WARNING_LIST_URLS,
]

// default lists to be 'active' aka searched across
// export const DEFAULT_ACTIVE_LIST_URLS: string[] = [PANCAKE_EXTENDED, PANCAKE_ETH_DEFAULT, PANCAKE_ETH_MM]
export const DEFAULT_ACTIVE_LIST_URLS: string[] = []
